@import './common.scss';

.NewPlanSelectModal {
  &-content {
    @include mt-8;
    @include ofy-scroll;
    margin: 0 -40px;
    display: flex;
    flex-direction: column;

    img {
      align-self: center;
      height: 200px;
      width: 200px;
      transform: scale(1.2);
    }
  }

  &-region-disclaimer {
    font-size: 1rem;
    margin: 1em 0;
  }
}
