.deep-linking-form {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.redirection-and-fallback {
  &__header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.75rem;
  }

  &__title {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 600;
    color: #000;
  }
}

.fallback-save-button {
  margin: 10px -16px 0;
  justify-self: flex-end;
}
