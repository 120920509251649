@import 'styles/_colors.scss';

.admin-page {
  padding: 32px;
  max-width: 960px;
  margin: 0 auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  p {
    color: $label;
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0 0 24px 0;
    padding: 8px 16px;
    border-bottom: 1px solid $border;

    input {
      border: 1px solid $border;
      border-radius: 4px;
      padding: 8px;
      min-width: 200px;
    }
  }

  &-table {
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid $border;

      font-weight: 500;
    }

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid $border;

      color: inherit;
      text-decoration: none;

      &:hover {
        background-color: $lightGrey;
      }
    }

    a.admin-page-table-item {
      cursor: pointer;
    }

    &-header,
    &-item {
      & > * {
        flex: 1;
        align-items: flex-start;
        padding: 8px 20px;
      }
    }

    &-error {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 64px 0;

      h3 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }
    }

    &-loading {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 32px 0;
    }
  }

  .chip {
    &.status {
      // solid colors
      &-active {
        background-color: $green;
      }

      &-rejected {
        color: white;
        background-color: $orange;
      }

      &-private {
        color: white;
        background-color: $purple;
      }

      // border colors
      &-testing {
        background-color: $lightYellow;
        border: 1px solid $yellow;
      }

      &-approved {
        background-color: $lightGreen;
        border: 1px solid $green;
      }
    }
  }

  .missing-logo {
    display: block;
    width: 32px;
    height: 32px;
  }
}

.tci-nav-link {
  color: $transcosmosRed;
}

.admin-users-page {
  padding: 30px;
  max-width: 1000px;
  margin: 0 auto;

  .admin-reset-password-form {
    display: flex;
    align-items: center;
    height: 75px;
    margin-top: -30px;
    margin-bottom: 30px;

    .admin-password-field {
      margin-right: 25px;

      input {
        width: 250px;
      }
    }

    > button {
      top: 9px;
    }
  }

  .admin-password-message {
    position: relative;
    bottom: 20px;
  }
}

.admin-user-item,
.admin-users-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 20px;
  border-bottom: 1px solid #ddd;
  margin: 0 -20px;
}

.admin-user-item > *,
.admin-users-header > * {
  flex: 1;
  align-items: flex-start;
}
.admin-users-header p {
  color: #8a8a8a;
}

a.admin-user-item {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.admin-user-item:hover {
  background: #f5f5f5;
}

.admin-user-item h5 {
  font-size: 14px;
  margin: 0;
}

.admin-user-item .app-icon {
  flex: unset;
  margin-right: 12px;
}

.admin-users-page h2 {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 18px;
}

.admin-users-page h1 + h2 {
  margin-top: -3em;
  margin-bottom: 3em;
}

.admin-users-page h3 {
  font-size: 14px;
}

.admin-users-page h2 + h3 {
  margin-top: -3em;
  margin-bottom: 3em;
}

.admin-org-status,
.admin-build-status,
.admin-role-status {
  display: inline-block;
  font-size: 10px;
  text-transform: capitalize;
  color: #fff;
  background-color: #bbb;
  padding: 3px 6px;
  border-radius: 3px;
  margin: 5px 0 5px 3px;
}

.admin-action-button {
  display: inline-block;
  text-transform: capitalize;
  color: #fff;
  background-color: #bbb;
  padding: 3px 5px;
  border-radius: 3px;
  margin: 5px 0 5px 3px;
  border: none;
  cursor: pointer;
}

.admin-action-button--upgrade {
  background-color: #00a898;
}

.admin-action-button--downgrade {
  background-color: #ef4c30;
}

.admin-org-paying,
.admin-build-built {
  background-color: #00a898;
}

.admin-org-free {
  background-color: #bbb;
}

.admin-role-admin {
  background-color: #00a898;
}

.admin-build-failed {
  background-color: #f00;
}

.admin-library-active {
  background-color: $green;
}

.admin-library-pending {
  color: $black;
  background-color: $yellow;
}

.admin-library-approved {
  background-color: $teal;
}

.admin-library-deprecated {
  background-color: $orange;
}

.admin-user-organization + .admin-user-organization:before {
  content: ', ';
}

.admin-user-org-sub {
  display: inline-block;
}

.admin-users-page .users-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.admin-users-page .users-header h1 {
  margin-bottom: 0;
}

.admin-users-page .users-header input {
  padding: 8px 12px;
  border-radius: 6px;
  width: 100%;
  max-width: 240px;
  border: 1px solid $darkGrey;
  font-size: 14px;

  &:focus {
    outline: none;
  }
}

.admin-build-status-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  > * + * {
    margin-left: 6px;
  }
}

.admin-users-page > h1 {
  margin-bottom: 0;
}

.admin-build-options {
  margin-bottom: 20px;

  > a {
    font-size: 30px;

    margin-left: 20px;
    text-decoration: none;
  }
}

.admin-debug-page {
  padding: 30px;
  max-width: 1000px;
  margin: 0 auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 10px;
    padding: 0;
  }

  .styled-table {
    border-collapse: collapse;
    margin-top: 5px;
    margin-bottom: 30px;
    min-width: 400px;
  }

  .styled-table thead tr {
    text-align: left;
  }

  .styled-table th,
  .styled-table td {
    padding: 5px 5px 5px 0;
  }

  .styled-table tbody tr {
    border-bottom: 1px solid lightgray;
  }

  .styled-table thead tr:last-of-type {
    border-bottom: 1px solid #009879;
  }

  .styled-table tbody tr:last-of-type {
    border: none;
  }
}
