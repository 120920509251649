.app-navbar {
  height: 64px;
  position: fixed;
  top: 0;
  left: -20px;
  right: -20px;
  z-index: 10000;
  background: #fff;
  padding: 4px 40px 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  transition: top 0.2s;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15),
    0 0 0 1px rgba(0, 0, 0, 0.03);
}

.offline-banner-visible .app-navbar {
  top: 32px;
}

.app-navbar a {
  color: inherit;
}

.app-navbar-brand {
  width: 100px;
  display: block;
  text-indent: -10000px;
  overflow: hidden;
  background: url('../logo.svg') no-repeat left;
  margin-right: 20px;
}

.app-navbar-brand--transcosmos {
  width: 100px;
  display: block;
  text-indent: -10000px;
  overflow: hidden;
  background: url('../logo_transcosmos.svg') no-repeat left;
  margin-right: 20px;
}

.navbar-spacer {
  flex: 1;
}

.navbar-user-info {
  display: flex;
  align-items: justify;
  position: relative;
}

.navbar-user-avatar {
  display: flex;
  align-items: center;
}

.navbar-user-avatar .icon {
  background: #ccc;
}

.navbar-user-menu {
  display: none;
  position: absolute;
  top: 67px;
  right: 0;
  background: #fff;
  width: 220px;
  margin-top: -4px;
  border-radius: 6px;
  padding: 10px 0;
  font-weight: 500;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  font-size: 14px;
  max-height: 80vh;
}

.expand-width {
  width: 260px;
}

.navbar-team-switcher {
  overflow-y: auto;
  min-height: 52px;
  margin-bottom: -10px
}

.navbar-team-switcher-item {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  padding: 10px;
}

.navbar-team-switcher-item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.navbar-team-switcher-item.selected {
  background-color: rgba(0, 168, 152, 0.12);
  cursor: pointer;
}

.navbar-team-switcher-item.selected:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.navbar-user-menu a {
  display: block;
  padding: 15px 20px;
  text-decoration: none;
}

.navbar-team-switcher-team-name {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.navbar-user-menu a:hover {
  background: #f5f5f5;
}

.navbar-user-menu hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #f1f1f1;
}

.navbar-user-menu.expanded {
  display: flex;
  flex-direction: column;
}

.app-navbar-stripe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
}

.navbar-dropdown-container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.navbar-dropdown-children {
  width: 360px;
  position: absolute;
  top: 54px;
  left: calc(50% - 180px);
  background: #fff;
  min-width: 220px;
  margin-top: -4px;
  border-radius: 6px;
  padding: 10px 0;
  font-weight: 500;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  max-height: 85vh;
}

.navbar-dropdown-children a {
  display: flex;
  padding: 15px 20px;
  text-decoration: none;
}

.navbar-dropdown-children a:hover {
  background: #f5f5f5;
}

.navbar-dropdown-children hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #f1f1f1;
}

.team-switcher-item-hire-expert {
  background: url('../../Shared/Icon/icons/adalo-sparkles.svg') no-repeat 125px/20px;
}

.navbar-user-menu .team-switcher-item-hire-expert:hover {
  color: #00a898;
  background: #f5f5f5 url('../../Shared/Icon/icons/adalo-sparkles.svg') no-repeat 125px/20px;
}
